import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// @ts-ignore
window.html2canvas = html2canvas;

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.sass'],
})
export class InvoiceComponent implements OnInit {
    @Input()
    public type: 'display' | 'print' = 'display';

    @Input()
    public invoice: any;

    @Input()
    public name: string = 'invoice.pdf';

    @Output()
    public resetPreview: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('renderedInvoice', { static: true })
    public renderedInvoice: ElementRef<HTMLElement>;

    public dialogRef: any;

    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {
        if (this.type === 'display') {
            const dialogRef = this.dialog.open(DialogInvoiceComponent, {
                width: '844px',
                // height: '1123px',
                data: { invoice: this.invoice },
            });

            dialogRef.afterClosed().subscribe((result) => {
                this.resetPreview.emit(true);
            });
        } else {
            this.printPDF();
        }
    }

    public totalPrice(items): any {
        let value = 0;
        items.map((item) => {
            value += item.quantity * item.price_unit;
        });

        return value + items[0].currency;
    }

    public printPDF(): void {
        const doc = new jsPDF({ orientation: 'p', format: 'a4' });
        setTimeout(() => {
            const invoiceEl = this.renderedInvoice.nativeElement as HTMLElement;
            var w = invoiceEl.offsetWidth;
            var h = invoiceEl.offsetHeight;
            html2canvas(invoiceEl, {
                dpi: 300, // Set to 300 DPI
                scale: 3, // Adjusts your resolution
            } as any).then((canvas) => {
                var img = canvas.toDataURL('image/jpeg', 1);
                var doc = new jsPDF('P', 'px', [w * 1.3, h * 1.3]);
                doc.addImage(img, 'JPEG', -10, -30, w, h);
                doc.save('sample-file.pdf');
                // doc.output('dataurlnewwindow');
            });
        }, 2000);
    }
}

@Component({
    selector: 'dialog-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.sass'],
})
export class DialogInvoiceComponent {
    public invoice: any;
    constructor(public dialogRef: MatDialogRef<DialogInvoiceComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.invoice = data.invoice;
    }

    public totalPrice(items): any {
        let value = 0;
        items.map((item) => {
            value += item.quantity * item.price_unit;
        });

        return value + items[0].currency;
    }
}
