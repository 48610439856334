import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-infobar-top',
    templateUrl: './ui-infobar-top.component.html',
})
export class UiInfobarTopComponent implements OnInit {
    public constructor() {}

    public ngOnInit(): void {}
}
