import { Injectable, NgZone } from '@angular/core';
import { User } from '../services/user';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    userData: any; // Save logged in user data
    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        public ngZone: NgZone, // NgZone service to remove outside scope warning
    ) {
        /* Saving user data in localstorage when
    logged in and setting up null when logged out */
        this.afAuth.authState.subscribe((user) => {
            if (user == null) {
                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user'));
            } else {
                this.SetUserData(user).then(() => {
                    this.ngZone.run(() => {
                        this.router.navigate(['dashboard']);
                    });
                });
            }
        });
    }

    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return user !== null && user.emailVerified !== false ? true : false;
    }

    // Sign in with Google
    GoogleAuth(): any {
        return this.AuthLogin(new auth.GoogleAuthProvider());
    }

    // Auth logic to run auth providers
    AuthLogin(provider): any {
        return this.afAuth
            .signInWithPopup(provider)
            .then((result) => {
                // Setting the user data is a test of the rules
                this.SetUserData(result.user).then(
                    () => {
                        this.userData = result.user;
                        localStorage.setItem(
                            'user',
                            JSON.stringify(this.userData),
                        );
                        JSON.parse(localStorage.getItem('user'));

                        this.ngZone.run(() => {
                            this.router.navigate(['dashboard']);
                        });
                    },
                    () => {
                        this.SignOut();
                        this.ngZone.run(() => {
                            this.router.navigate(['not-allowed']);
                        });
                    },
                );
            })
            .catch((error) => {
                window.alert(error);
            });
    }

    /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    SetUserData(user): any {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `users/${user.uid}`,
        );
        const userData: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
        };
        return userRef.set(userData, {
            merge: true,
        });
    }

    // Sign out
    SignOut(witNavigate = false): any {
        return this.afAuth.signOut().then(() => {
            localStorage.removeItem('user');
            if (witNavigate) {
                this.router.navigate(['sign-in']);
            }
        });
    }
}
