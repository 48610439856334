import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-bad-user',
    templateUrl: './bad-user.component.html',
    styleUrls: ['./bad-user.component.sass'],
})
export class BadUserComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
