import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-infobar-bottom',
    templateUrl: './ui-infobar-bottom.component.html',
})
export class UiInfobarBottomComponent implements OnInit {
    public constructor() {}

    public ngOnInit(): void {}
}
