<div fxFill fxLayout="column">
  <div fxFlex="50%" fxLayout="row" fxLayoutAlign="center center">
    <ffui-logo></ffui-logo>
  </div>
  <div fxFlex="50%" fxLayout="row" fxLayoutAlign="center start">
    <button mat-flat-button (click)="authService.GoogleAuth()">
      <mat-icon color="primary">account_circle</mat-icon> Autentificare
    </button>
  </div>
</div>

