import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from 'projects/invoicing/src/app/shared/services/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { BackendService } from 'projects/invoicing/src/app/shared/services/backend.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.sass'],
})
export class DashboardComponent implements OnInit {
    public invoiceProcessing: {
        processInvoice: boolean;
        processType: 'display' | 'print';
        name: string;
        data: any;
    };

    public invoices: any[] = [];

    public nextInvoiceNumber = 1;

    public demoInvoice = {
        client: {
            name: 'Engaging Solutions AB',
            address: 'Anckargripsgatan 3, 211 19',
            city: 'Malmò',
            country: 'Sweden',
            cui: false,
            reg: false,
            fields: [['VAT ID', 'SE559150735401']],
        },
        self: {
            name: 'The Form Factory S.R.L.',
            address: 'str. G-ral Traian Mosoiu, nr. 54, ap. 6',
            city: 'Cluj-Napoca',
            county: 'Cluj',
            country: 'Romania',
            cui: '42669770',
            reg: 'J12/1736/23.06.2020',
            bank: {
                bank_name: 'Banca Transilvania',
                name: 'THE FORM FACTORY SRL',
                swift: 'BTRLRO22',
                accounts: [
                    ['EUR', 'RO39BTRLEURCRT0556770101'],
                    ['RON', 'RO89BTRLRONCRT0556770101'],
                ],
            },
            fields: [['VAT ID', 'RO42684478']],
        },
        invoice_number: '0002',
        invoice_issued: '03/08/2020',
        invoice_due: '15/08/2020',
        items: [
            {
                description: 'IT Consultancy services - Reverse Charge',
                quantity: 1,
                price_unit: 4500,
                currency: '€',
            },
            {
                description: 'Learning day snacks + drinks',
                quantity: 1,
                price_unit: 14,
                currency: '€',
            },
        ],
        contact_email: 'arthur@form-factory.studio',
        contact_phone: '+40721556600',
    };

    constructor(private backend: BackendService) {}

    ngOnInit(): void {
        this.getInvoices();
    }

    public saveInvoice(): void {
        this.backend.getData('invoices').then((nrs) => {
            const payload = {
                invoice_number: nrs.length + 1,
                invoice_data: JSON.stringify(this.demoInvoice),
                invoice_date: new Date(),
            };

            this.backend.setData(payload, 'invoices').then((res) => {
                console.log(res);
                setTimeout(() => {
                    this.getInvoices();
                }, 1000);
            });
        });
    }
    public previewInvoice(invoice): void {
        this.invoiceProcessing = {
            processInvoice: true,
            processType: 'display',
            name: 'Arthur Kovacs',
            data: this.demoInvoice,
        };
    }
    public printInvoice(invoice): void {
        this.invoiceProcessing = {
            processInvoice: true,
            processType: 'print',
            name: 'Arthur Kovacs',
            data: this.demoInvoice,
        };

        setTimeout(() => {
            this.invoiceProcessing.processInvoice = false;
        }, 2500);
    }

    public getInvoices(): void {
        this.backend.getData('invoices').then((res) => {
            this.invoices = res;
        });
    }

    public getInvoiceTotal(invoice_data: any): string {
        const invoice = JSON.parse(invoice_data);
        let price = 0;
        let priceCurrency = '';
        invoice.items.map((item) => {
            priceCurrency = item.currency;
            price += item.price_unit * item.quantity;
        });
        return `${invoice.client.name} - ${price}${priceCurrency}`;
    }
    public getInvoiceDate(invoice_data: any): string {
        const invoice = JSON.parse(invoice_data);
        return `${invoice.invoice_issued} - scadenta in ${invoice.invoice_due}`;
    }

    public resetInvoicePreview($event: any): void {
        this.invoiceProcessing.processInvoice = false;
    }
}
