import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthService } from './shared/services/auth-service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BadUserComponent } from './components/bad-user/bad-user.component';
import { FormFactoryUiModule } from 'projects/form-factory-ui/src/lib/form-factory-ui.module';
import { DialogInvoiceComponent, InvoiceComponent } from './components/invoice/invoice.component';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        AppComponent,
        SignInComponent,
        DashboardComponent,
        BadUserComponent,
        InvoiceComponent,
        DialogInvoiceComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp({
            apiKey: 'AIzaSyDIkrAbtZZz41SsK7O14mMhAxP3HQVZgBo',
            authDomain: 'form-factory-studio.firebaseapp.com',
            databaseURL: 'https://form-factory-studio.firebaseio.com',
            projectId: 'form-factory-studio',
            storageBucket: 'form-factory-studio.appspot.com',
            messagingSenderId: '345964555811',
            appId: '1:345964555811:web:9c73e45680620407dd0b96',
            measurementId: 'G-BFY27F3HZ0',
        }),
        AngularFireAuthModule,
        AngularFirestoreModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        FormFactoryUiModule,
        MatListModule,
    ],
    providers: [AuthService],
    bootstrap: [AppComponent],
    entryComponents: [DialogInvoiceComponent],
})
export class AppModule {}
