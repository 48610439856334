<div class="invoice" fxLayout="column" #renderedInvoice id="renderedInvoice" [ngClass]="{'in-dialog': dialogRef}">
  <div class="logo-title" fxLayout="row" fxLayoutAlign="start center">
      <ffui-logo-simple></ffui-logo-simple> <h1>Invoice</h1>
    <!-- TODO: i18n the whole thing. -->
  </div>

  <div class="parties" fxLayout="row">
    <div class="client" fxFlex="35">
      <h2>{{invoice.client.name}}</h2>
      <div *ngIf="invoice.client.address">{{invoice.client.address}}</div>
      <div *ngIf="invoice.client.city">{{invoice.client.city}}</div>
      <div *ngIf="invoice.client.country">{{invoice.client.country}}</div>
      <div *ngIf="invoice.client.cui">{{invoice.client.cui}}</div>
      <div *ngIf="invoice.client.reg">{{invoice.client.reg}}</div>
      <div *ngFor="let field of invoice.client.fields">
        {{field[0]}} <strong>{{field[1]}}</strong>
      </div>
    </div>
    <div fxFlex="30">
      <!-- TODO: i18n this shit -->
      <h2 style="color: #fff"> A </h2>
      <div> Invoice No <strong>{{invoice.invoice_number}}</strong></div>
      <div> Invoice Date <strong>{{invoice.invoice_issued}}</strong></div>
      <div> Invoice Due <strong>{{invoice.invoice_due}}</strong></div>
    </div>
    <div class="self" fxFlex="35">
      <h2>{{invoice.self.name}}</h2>
      <div *ngIf="invoice.self.address">{{invoice.self.address}}</div>
      <div *ngIf="invoice.self.city">{{invoice.self.city}}</div>
      <div *ngIf="invoice.self.county">{{invoice.self.county}}</div>
      <div *ngIf="invoice.self.country">{{invoice.self.country}}</div>
      <div *ngIf="invoice.self.cui">CUI <strong>{{invoice.self.cui}}</strong></div>
      <div *ngIf="invoice.self.reg"> Nr. Reg. Com. <strong>{{invoice.self.reg}}</strong></div>
      <div *ngFor="let field of invoice.self.fields">
        {{field[0]}} <strong>{{field[1]}}</strong>
      </div>
    </div>
  </div>

  <div class="items">
    <div class="items-header" fxLayout="row">
      <div class="description" fxFlex>Item description</div>
      <div class="quantity">Qty</div>
      <div class="unit_price">Price per unit</div>
      <div class="price">Total price</div>
    </div>
    <div class="item" *ngFor="let item of invoice.items" fxLayout="row">
      <div class="description" fxFlex>{{item.description}}</div>
      <div class="quantity">{{item.quantity}}</div>
      <div class="unit_price">{{item.price_unit}}{{item.currency}}</div>
      <div class="price">{{item.price_unit * item.quantity}}{{item.currency}}</div>
    </div>
  </div>

  <div class="details" fxFlex fxLayoutAlign="start end">
    <div class="inside">
      <div class="details-header" fxLayout="row">
        <div fxFlex="33">Bank Info</div>
        <div style="text-align: center" fxFlex="33">Due By</div>
        <div style="text-align: right" fxFlex="33">Total Due</div>
      </div>
      <div class="details-content" fxLayout="row">
        <div fxFlex="33">
          <div>Bank name: <strong>{{invoice.self.bank.bank_name}}</strong></div>
          <div>Swift: <strong>{{invoice.self.bank.swift}}</strong></div>
          <div>Name: <strong>{{invoice.self.bank.name}}</strong></div>
          <div>Accounts</div>
          <div *ngFor="let account of invoice.self.bank.accounts">
            {{account[0]}}: <strong>{{account[1]}}</strong>
          </div>
        </div>
        <div class="due" style="text-align: center" fxFlex="33" fxLayoutAlign="center center">
          {{invoice.invoice_due}}
        </div>
        <div class="total" style="text-align: right" fxFlex="33"  fxLayoutAlign="end center">
          {{totalPrice(invoice.items)}}
        </div>
      </div>
      <div class="contact" fxLayout="row">
        <span class="thanks" fxLayoutAlign="center center"><mat-icon>favorite</mat-icon>  Thank you!</span>
        <span fxFlex></span>
        <span>{{invoice.contact_email}} |</span>
        <span>{{invoice.contact_phone}} |</span>
        <span>www.form-factory.studio</span>
      </div>

    </div>
  </div>

  <button mat-fab *ngIf="dialogRef" (click)="dialogRef.close()" class="invoice-close"><mat-icon>close</mat-icon></button>

</div>
