<mat-list>
  <mat-list-item *ngFor="let invoice of invoices">
    <img matListAvatar [src]="invoice.created_by_photo">
    <h3 matLine>
      <strong>#{{invoice.invoice_number}} </strong>- {{getInvoiceTotal(invoice.invoice_data)}}
      </h3>
    <div matLine>
      <span> {{invoice.created_by_name}} </span>
      <span class="demo-2"> -- {{getInvoiceDate(invoice.invoice_data)}} </span>
    </div>
    <div matListAvatar style="position: absolute; right: 0; width: 100px">
      <button mat-mini-fab color="primary" (click)="previewInvoice(invoice)" style="margin-right: 6px">
        <mat-icon>preview</mat-icon>
      </button>

      <button mat-mini-fab (click)="printInvoice(invoice)" color="primary">
        <mat-icon>print</mat-icon>
      </button>
    </div>
  </mat-list-item>
</mat-list>






<!--&lt;!&ndash; DEMO STUFF BELOW &ndash;&gt;-->
<!--<button mat-flat-button (click)="saveInvoice()">-->
<!--  <mat-icon color="primary">save</mat-icon> Save Invoice-->
<!--</button>-->

<!--<button mat-flat-button (click)="getInvoices()">-->
<!--  <mat-icon color="primary">save</mat-icon> Get Invoices-->
<!--</button>-->




<!-- This will display the as a modal or download it as PDF -->
<div *ngIf="invoiceProcessing && invoiceProcessing.processInvoice" style="opacity: 0; position: absolute">
  <app-invoice [type]="invoiceProcessing.processType" [invoice]="invoiceProcessing.data" [name]="invoiceProcessing.name" (resetPreview)="resetInvoicePreview($event)"></app-invoice>
</div>

