import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-sidebar-right',
    templateUrl: './ui-sidebar-right.component.html',
})
export class UiSidebarRightComponent implements OnInit {
    public constructor() {}

    public ngOnInit(): void {}
}
